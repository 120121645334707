import {graphql, withPrefix} from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import {Button} from '../../../components/button';
import {CaseHeader} from '../../../components/case_header';
import {WillAnimate} from '../../../components/content_animate';
import {Layout} from '../../../components/layout';
import {ScrollTo} from '../../../components/scroll_to';
import machineLearning from '../../../images/portfolio/fairlingo/machine-learning-2.gif';

const locale = 'en';
const translatedPagePath = '/nl/portfolio/vertaalplatform-fairlingo/';

export default ({data}: any) => (
    <Layout
        title="Case: Translation platform Fairlingo – Everything about the development of the Fairlingo platform – Showcase of our work"
        description="Fairlingo is an online translation platform where customer and translator come together. Transparency and technical depth make it all possible."
        ogImage="fairlingo.png"
        navColor="white"
        locale={locale}
        translatedPagePath={translatedPagePath}>
        <CaseHeader
            urlBack="/en/portfolio/saas-product-keeping/"
            titleBack="Case: Our own product: Keeping"
            urlForward="/en/portfolio/valuation-platform-taxapi/"
            titleForward="Case: Valuation platform TaXapi"
            locale={locale}
            translatedPagePath={translatedPagePath}>
            <Img className="hero__image" alt="RP3" fluid={data.fairlingo.childImageSharp.fluid} critical />

            <div className="hero__content-wrapper">
                <div className="hero__content">
                    <h1>
                        {/* Make sure to have the correct spacing when br becomes invisible */}
                        The Uber of <br /> translation
                    </h1>
                    <h2>Translation platform Fairlingo</h2>
                </div>
            </div>
            <ScrollTo className="hero__scroll" target="/en/portfolio/translation-platform-fairlingo/#from-hero" />
        </CaseHeader>

        <section className="section">
            {/* Case intro */}
            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__display content__display--intro">
                        <h2>
                            Fairlingo is one of Europe’s biggest translation platforms. Fairlingo sits at the forefront
                            of the translation industry, thanks to the translation platform’s{' '}
                            <span className="highlight">innovative approach</span> and slick functionality. Together
                            with its founder, we developed Fairlingo from scratch, into the total translation solution
                            it is today.
                        </h2>
                    </div>
                </div>
            </WillAnimate>

            {/* Case assignment */}
            <WillAnimate as="article" id="from-hero" className="content">
                <div className="container">
                    <div className="content__display content__display--right">
                        <h2>The assignment</h2>
                    </div>

                    <div className="content__copy">
                        <p className="p-lead">
                            Fairlingo’s founder had been active in the world of translation for more than ten years,
                            mainly with his own translation agency. He came to us with an innovative idea for an online
                            translation platform.
                        </p>
                        <p>
                            What would it need to be? A place where everyone could easily and directly translate texts
                            with a high-quality profile, guaranteed to be done by real{' '}
                            <span className="highlight">human translators</span>. A transparent, modern and, above all,
                            <a target="_blank" href="https://www.fairlingo.com/en">
                                user-friendly translation platform
                            </a>
                            . We were immediately very keen and joined as a strategic and technical partner.
                        </p>
                        <p>
                            Together we took up the challenge to develop a software product where translator and client{' '}
                            <span className="highlight">come together</span>. A stand-alone platform where the quality
                            and transparency of a translation quote and the process itself stand paramount. An
                            intelligent, versatile and fine addition to the{' '}
                            <span className="highlight">app economy</span>.
                        </p>
                        <ul className="list">
                            <li className="ion-md-arrow-dropright">Conceiving and devising a product plan</li>
                            <li className="ion-md-arrow-dropright">Branding, UX and UI design</li>
                            <li className="ion-md-arrow-dropright">
                                Technical platform development using Laravel and React
                            </li>
                            <li className="ion-md-arrow-dropright">
                                AI-driven suggestions system to assist human translators
                            </li>
                            <li className="ion-md-arrow-dropright">Keeping track of platform availability</li>
                        </ul>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="div" className="content">
                <div className="image">
                    <div className="image__content">
                        <Img
                            alt="3D rendering of the various elements in the Fairlingo translation interface"
                            fluid={data.fairlingo3d.childImageSharp.fluid}
                        />
                    </div>
                    <div className="image__caption">
                        <span className="ion-md-arrow-dropright">
                            The Fairlingo interface. Simple, productive and made for translators.
                        </span>
                    </div>
                </div>
            </WillAnimate>

            {/* Case challenge */}
            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__display">
                        <h2>The challenge</h2>
                    </div>

                    <div className="content__copy">
                        <p className="p-lead">
                            Every translation assignment is <span className="highlight">unique</span>, so the Fairlingo
                            platform had to be able to adapt to the demands of multiple industries, assignments and
                            clients from different countries, with many different languages.
                        </p>
                        <p>
                            A major challenge in developing the translation platform was to design and implement UX that
                            immediately felt intuitive for everyone. Both for clients with a translation assignment, and
                            for translators who complete assignments. We wanted to assist translators using{' '}
                            <span className="highlight">smart tools</span>, so that they could focus fully on the
                            translation assignment.
                        </p>
                        <p>
                            To ensure that translations are of high-quality, we had to set up a process where other
                            participating translators assess, review and possibly revise translation work. This process
                            had to reward great translations and discourage poor translations.
                        </p>
                        <p>
                            The platform had to be versatile enough to cope with the many different{' '}
                            <span className="highlight">file formats</span> in which source texts can be supplied. In
                            addition, translations had to be delivered in the same file format, where possible.
                        </p>
                        <p>
                            Both client and translator want continuous oversight of the translation process from start
                            to finish. That meant always being able to{' '}
                            <span className="highlight">track an assignment</span> in real time in Fairlingo, from quote
                            to final delivery.
                        </p>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content-group">
                        <div className="content-group__background content-group__background--right" />
                        <div className="content__image">
                            <div className="image image--detail">
                                <div className="image__content">
                                    <Img
                                        alt="Example of a list with creating an order in Fairlingo"
                                        fluid={data.fairlingoOrder.childImageSharp.fluid}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="content__copy">
                            <h2>Add assignments easily</h2>
                            <p>
                                The user interface in Fairlingo makes it as intuitive as possible for clients to add new
                                assignments. The most important functions are immediately within reach: uploading your
                                document, choosing the source language, the target language and requesting a quote. You
                                will receive the best possible price for the translation assignment{' '}
                                <span className="highlight">within 30 seconds</span>, thanks to the transparent process
                                and smart algorithms that can handle all kinds of file formats.
                            </p>
                            <p>
                                In addition, you as client can track your translation in real time, including interim
                                versions. You also have the option of selecting the complexity level for your
                                translation. We have added an option for choosing a translator that you have worked with
                                before, indispensable if you want to have certain terminology translated consistently
                                across various translation assignments.
                            </p>
                        </div>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__image content__image--right">
                        <div className="image image--detail">
                            <div className="image__content">
                                <Img
                                    alt="Fairlingo interface with balance and job search"
                                    fluid={data.fairlingoMoneys.childImageSharp.fluid}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="content__copy">
                        <h2>The App Economy</h2>
                        <p>
                            As a translator you can easily sign up for Fairlingo and following a number of verification
                            steps you can start accepting assignments immediately. You indicate your language
                            preferences and immediately have translation assignments you can choose from. This means you
                            also determine your own work pace and number of assignments that you take on.
                        </p>
                        <p>
                            All translations submitted by a translator are checked by a second translator, to ensure the
                            quality of the translation and to keep the process transparent for the client. Once the
                            translation is approved, payment is sent to your account{' '}
                            <span className="highlight">immediately</span>. It’s that easy.
                        </p>
                        <p>
                            As you complete more translations, you will not only earn good money with Fairlingo, but you
                            will also earn <span className="highlight">experience points</span>. Having earned a good
                            number of points, you can gain access to specialist translation assignments which attract a
                            higher rate.
                        </p>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content-group">
                        <div className="content-group__background content-group__background--right" />
                        <div className="content__image">
                            <div className="image image--detail">
                                <div className="image__content">
                                    <img
                                        alt="Fairlingo machine learning translation model in action"
                                        src={machineLearning}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="content__copy">
                            <h2>Smart suggestions</h2>
                            <p>
                                In just a few weeks, we developed a prototype{' '}
                                <span className="highlight">suggestions system</span> for Fairlingo to assist human
                                translators. We did this by training a neural translation model using TensorFlow and the
                                data we had at our disposal.
                            </p>
                            <p>
                                The system worked rapidly and made good suggestions when translating an assignment.
                                Unlike translation sites, our suggestions system includes some of the text already
                                translated in the suggestion. The autocomplete function automatically adjusts
                                suggestions when translating. A perfect tool for translators. Instead of working in
                                competition, AI now helps human translators. They complement each other, making the
                                translation process even smoother and better.
                            </p>
                            <p>
                                Incorporating <span className="highlight">machine learning</span> into a product can
                                bring various benefits, such as a unique selling point compared with other services. For
                                more information on the machine learning model we have developed, and the results from
                                it, we have written a{' '}
                                <a
                                    target="_blank"
                                    title="Paper over interactive neural translation (PDF)"
                                    href={withPrefix('/files/scheepers-schulz-interactive-neural-translation.pdf')}>
                                    scientific paper (PDF)
                                </a>{' '}
                                .
                            </p>
                        </div>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="div" className="content">
                <div className="image">
                    <div className="image__content">
                        <Img
                            alt="Different screens of the Fairlingo translation platform"
                            fluid={data.fairlingoScreens.childImageSharp.fluid}
                        />
                    </div>
                    <div className="image__caption">
                        <span className="ion-md-arrow-dropright">
                            Fairlingo offers both client and translator the opportunity to track the process
                            continuously. This very text, that you are reading now, was also translated using Fairlingo.
                            We really wanted to showcase it on both the Dutch and English versions of our
                            website&mdash;the ideal application for Fairlingo, right?
                        </span>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__display content__display--right">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Developing <br />
                            Iterative <br />
                            software
                        </h2>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            Working closely with Fairlingo's <span className="highlight">product owner</span> ensures
                            that we are always aware of future development plans.
                        </p>
                        <p>
                            Being aware of the long-term plans for a product gives us the opportunity to work
                            iteratively on new functionality. Using a flexible development process, we produce beta
                            updates that are tested by the product team at Fairlingo.
                        </p>
                        <p>
                            <span className="highlight">Testing new functionality</span> using real translators always
                            benefits the product. This way you get insider knowledge from users that you would not
                            otherwise have been able to incorporate into a new product version.
                        </p>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="div" className="content">
                <div className="image">
                    <div className="image__content">
                        <Img
                            alt="Timeline of how the translation interface evolved"
                            fluid={data.fairlingoTimeline.childImageSharp.fluid}
                        />
                    </div>
                    <div className="image__caption">
                        <span className="ion-md-arrow-dropright">
                            Long-term cooperation in the picture. The Fairlingo interface has different faces: from an
                            interface with influences from skeuomorphism, to a light, clear interface.
                        </span>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Do you want <br />
                            to create a <br />
                            platform too?
                        </h2>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            In Fairlingo, we have created a unique platform that brings together translators and clients
                            with translation assignments. Do you have an interesting idea for an{' '}
                            <span className="highlight">app economy platform</span>, too? Contact{' '}
                            <span className="inline-avatar">
                                <Img alt="Olav" fixed={data.olav.childImageSharp.fixed} Tag="span" />
                                <span>Olav</span>
                            </span>{' '}
                            for an exploratory chat.
                        </p>
                    </div>
                    <div className="content__buttons content__buttons--50">
                        <Button url="/en/contact/" name="Contact us" title="Contact information Enschede" />
                    </div>
                </div>
            </WillAnimate>
        </section>
    </Layout>
);

export const query = graphql`
    query {
        fairlingo: file(relativePath: {eq: "portfolio/fairlingo/fairlingo.jpg"}) {
            ...fullscreenHero
        }

        olav: file(relativePath: {eq: "people/olav_2_zoom.jpg"}) {
            ...inlineAvatarImage
        }

        fairlingoScreens: file(relativePath: {eq: "portfolio/fairlingo/screens.png"}) {
            ...fullscreenHero
        }
        fairlingoOrder: file(relativePath: {eq: "portfolio/fairlingo/order.png"}) {
            ...fluid1200Width
        }
        fairlingoMoneys: file(relativePath: {eq: "portfolio/fairlingo/moneys.png"}) {
            ...fluid1200Width
        }
        fairlingo3d: file(relativePath: {eq: "portfolio/fairlingo/3d.png"}) {
            ...fullscreenHero
        }
        fairlingoTimeline: file(relativePath: {eq: "portfolio/fairlingo/timeline.png"}) {
            ...fullscreenHero
        }
    }
`;
